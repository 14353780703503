import { app } from "../../constants/common";

const french = {
  landing: {
    welcome: `Bienvenue sur la plateforme de soumission des paiements du ${app.groupName.fr}`,
    readInfo: "Lisez attentivement les informations ci-dessous.",
    howItWorks: "COMMENT ÇA MARCHE",
    step01: {
      title: "ÉTAPE 01",
      headline: "DISPOSEZ DE VOTRE CODE CLIENT",
      content:
        "Vous aurez besoin de votre code client pour continuer avec la soumission du paiement. Assurez-vous de voir et de confirmer votre nom de client, votre dépôt et votre numéro d'enregistrement",
    },
    step02: {
      title: "ÉTAPE 02",
      headline:
        "CONFIRMER LE MONTANT DU PAIEMENT ET CHOISIR LE MODE DE PAIEMENT",
      content:
        "Vous aurez la possibilité d'entrer votre montant et de sélectionner le mode de paiement. Vous vous assurerez également que le bon destinataire est sélectionné dans le menu déroulant.",
    },
    step03: {
      title: "ÉTAPE 03",
      headline:
        "CONFIRMER ET EFFECTUER DES PAIEMENTS EN UTILISANT VOTRE MÉTHODE DE PAIEMENT",
      content:
        "Vous recevrez une demande de confirmation du paiement sur votre numéro de téléphone mobile. Assurez-vous que le paiement a été validé sur l'appareil mobile pour continuer",
    },
    skip: "SUIVANT",
    allRightsReserved: "© 2021 Tous droits réservés.",
  },
  termsAndConditions: {
    tabTitle: "Mentions légales",
    beforeYouContinue: "Avant de continuer, lisez la mention légale",
    pleaseRead1: "Veuillez lire et confirmer les ",
    pleaseRead2: "conditions générales",
    pleaseRead3: " d'utilisation",
    iHave: "J'ai lu et compris les conditions générales",
    buttonAccept: "Accepter les conditions générales",
    modal: {
      title: "Conditions générales",
      paragraph1:
        "Les conditions de service (également connues sous le nom de conditions d'utilisation et de conditions générales, communément abrégées en CDS ou CDU, CG) sont les accords juridiques entre un fournisseur de services et une personne qui souhaite utiliser ce service. La personne doit accepter de se conformer aux conditions de service pour pouvoir utiliser le service proposé.",
      paragraph2:
        "Les conditions d'utilisation peuvent aussi n'être qu'une simple mention légale, notamment en ce qui concerne l'utilisation des sites web. Le langage vague et les longues phrases utilisés dans les conditions d'utilisation ont suscité des inquiétudes quant à la vie privée des clients et sensibilisé le public à bien des égards.  L'accord sur les conditions d'utilisation est principalement utilisé à des fins juridiques par les entreprises qui fournissent des logiciels ou des services, tels que les navigateurs web, le commerce électronique, les moteurs de recherche web, les médias sociaux et les services de transport.  Un accord légitime sur les conditions de service est juridiquement contraignant et peut faire l'objet de modifications.",
      paragraph3:
        "Les entreprises peuvent faire respecter les conditions en refusant le service. Les clients peuvent les faire respecter en intentant un procès ou une procédure d'arbitrage s'ils peuvent prouver qu'ils ont été réellement lésés par une violation des conditions. Il existe un risque accru de perte de données lors de changements au sein de l'entreprise, notamment lors de fusions, de cessions, de rachats, de réductions d'effectifs, etc.",
      buttonClose: "FERMER",
      allRightsReserved: "© 2021 Tous droits réservés.",
    },
    errorRecaptcha: "La vérification du reCAPTCHA a échoué!",
  },
  search: {
    tabTitle: "Rechercher",
    stepBlock: {
      title: "Étape 01",
      headline: "Entrez votre code client et recherchez",
      firstBullet: `Il s'agit d'un code unique du ${app.groupName.fr}`,
      secondBullet:
        "Assurez-vous que vous l'entrez exactement comme il vous a été donné",
      thirdBullet: `Si les résultats de la recherche sont incorrects. Contactez votre référent chez ${app.name}`,
    },
    panel: {
      enterYour: "Entrez le code client: ",
      buttonSearch: "RECHERCHE",
      searchResultsBelow: "Résultats de la recherche ci-dessous",
      customerName: "Nom du client",
      customerDepot: "Dépôt du client",
      registrationNumber: "Numéro d'enregistrement",
      company: "Société",
      error: "ERREUR: ",
      errorMessageNoValue: "AUCUN CODE CLIENT N'EST FOURNI",
      errorMessageInvalidCustomerCode: "LE FORMAT DU CODE CLIENT EST INCORRECT",
      errorRecaptcha: "La vérification du reCAPTCHA a échoué!",
      continueToPayment: "CONTINUER LE PAIEMENT",
    },
  },
  payment: {
    tabTitle: "Paiement",
    stepBlock: {
      title: "Étape 02",
      headline: "Entrez les détails de votre paiement",
      firstBullet: "Entrez le montant",
      secondBullet: "Confirmer que le dépôt du receveur est correct",
      thirdBullet: "Sélectionnez votre mode de paiement",
    },
    panel: {
      yourCustomerCode: "Votre code client:",
      customerName: "Nom du client:",
      enterAmount: "Entrez le montant",
      phoneNumber: "Entrez votre numéro de téléphone",
      selectPaymentMethod: "Sélectionnez le mode de paiement",
      proceed: "Procéder au paiement",
      error: "ERREUR: ",
      errorMessagePaymentRequired: "MONTANT REQUIS DU PAIEMENT",
      errorMessagePhoneRequired: "NUMÉRO DE TÉLÉPHONE REQUIS",
      errorMessageNoPhone:
        "Contactez l'équipe support SABC pour l'ajout du numéro.",
      errorMessagePhoneInvalid: "LE NUMÉRO DE TÉLÉPHONE EST INVALIDE",
      errorMessagePhoneInvalidProvider:
        "LE NUMÉRO DE TÉLÉPHONE EST INVALIDE POUR CE FOURNISSEUR",
      errorMessageMissingData:
        "Votre numéro de téléphone n’est pas enregistré. Veuillez contacter l’administrateur.",
      previous: "Précédent",
    },
  },
  paymentConfirm: {
    instruction: {
      mtn: "Pour entrer votre code, prière de composer le *126#",
      orange: "Pour entrer votre code, prière de composer le #150*50#",
      yup: "",
    },
    tabTitle: "Confirmation du paiement",
    stepBlock: {
      title: "Étape 03",
      headline: "Entrez les détails de votre paiement",
      firstBullet: "Vérifiez votre code client",
      secondBullet: "Vérifiez votre montant",
      thirdBullet: "Vérifiez votre numéro de téléphone",
    },
    panel: {
      instruction: "Instructions",
      title: "Confirmation du paiement",
      yourCustomerCode: "Votre code client:",
      customerName: "Nom du client:",
      paymentAmount: "Montant du paiement:",
      customerDepot: "Dépôt du client",
      paymentMethod: "Mode de paiement:",
      phoneNumber: "Numéro de téléphone:",
      termsAndConditions1: "Conditions générales:",
      termsAndConditions2: " ACCEPTÉ ",
      termsAndConditions3: "sur",
      buttonConfirm: "Confirmer le paiement",
      error: "ERREUR: ",
      errorMessageMissingData:
        "Il y a des données manquantes ! Veuillez revenir en arrière et réessayer. Si le problème persiste, veuillez contacter le service d'assistance.",
    },
  },
  paymentSuccess: {
    tabTitle: "Paiement réussi",
    panel: {
      title: "Paiement effectué avec succès!",
      yourCustomerCode: "Votre code client:",
      customerName: "Nom du client:",
      paymentAmount: "Montant du paiement:",
      customerDepot: "Dépôt du client:",
      paymentMethod: "Mode de paiement:",
      phoneNumber: "Numéro de téléphone:",
      paymentsConfirmed: "Date du paiement:",
      shareReceipts: "Partager les reçus",
      processingPayment: "Traitement du paiement...",
      buttonMakeAnotherPayment: "Effectuer un autre paiement",
      errorPaymentFailed:
        "Le système n'a pas réussi à traiter le paiement. Veuillez nous contacter.",
      error: "ERREUR: ",
      errorMessageMissingData:
        "Il y a des données manquantes ! Veuillez revenir en arrière et réessayer. Si le problème persiste, veuillez contacter le service d'assistance.",
    },
  },
  serverErrorMessages: {
    40002: "La clé d'accès n'est pas valide (X-API-KEY non autorisé)",
    40003:
      "La clé d'accès de l'agent n'est pas valide (l'agent n'est pas configuré dans l'API)",
    40010: "La requête ne comporte pas certains champs obligatoires.",
    40301:
      "L'initiation n'a pas pu être achevée car le destinataire n'est pas valide.",
    40302:
      "Le montant est plus élevé ou plus bas que celui prévu. ({{originalErrorCode}})",
    40409:
      "Veuillez entrer un montant valide car le montant entré est trop petit ou trop élevé. ({{originalErrorCode}})",
    40602: "L'identifiant du service n'est pas valide.",
    40604: "L'élément de paiement n'a pas été trouvé.",
    40605: "Le service n'est pas supporté.",
    40607: "Le mode de paiement n'est pas valide ou n'est pas actif.",
    40610: "Erreur de paiement. Le système n'a pas pu créer de devis.",
    40617:
      "Aucun paiement trouvé pour les détails fournis. ({{originalErrorCode}})",
    40702:
      "Double paiement détecté! Veuillez réessayer après 5 minutes ou changer le montant du paiement.",
    41003:
      "L'opérateur est actuellement indisponible. Veuillez réessayer plus tard ou contacter le support.",
    41004:
      "L'opérateur est actuellement indisponible. Veuillez réessayer plus tard ou contacter le support.",
    50000: "Erreur générique ! Contactez le support. ({{originalErrorCode}})",
    50001: "Erreur de configuration sur le serveur. Contactez le support.",
    703103: "Le compte de l'utilisateur est bloqué chez l'opérateur.",
    703104: "Votre compte est bloqué. Contactez votre fournisseur de services.",
    703106:
      "ERREUR: Le fournisseur de service ne connaît pas le compte du payeur/initiateur de la transaction. (703106).",
    703108:
      "Vous ne disposez pas de fonds suffisants pour effectuer cette transaction.",
    703111:
      "Vous avez dépassé votre limite quotidienne/hebdomadaire/mensuelle.",
    703112:
      "Vous avez dépassé votre limite quotidienne/hebdomadaire/mensuelle.",
    703117:
      "Le numéro de téléphone saisi n'est pas reconnu par le fournisseur de paiement !",
    703201: "Vous n'avez pas validé le paiement avec votre code PIN.",
    703202: "Vous avez rejeté ce paiement.",
    703203: "Vous avez saisi un code PIN incorrect.",
  },
};

export default french;
