export const app = {
  name: "CASTEL",
  appTitle: "Groupe CASTEL",
  groupName: {
    en: "CASTEL group",
    fr: "groupe CASTEL",
  },
  backgroundImage: "url(./images/background1.webp)",
  logo: "/images/groupCASTELLogo.png",
  customerCodeRegex: "^[a-zA-Z0-9]{7,10}$",
};
