import I18n from "../i18n/I18n";
const exception = (errorCode) => {
  return {
    defaults: [{ scope: "serverErrorMessages.50000" }],
    originalErrorCode: errorCode,
  };
};

export const manageError = (errorCode) => {
  switch (errorCode) {
    case 40002:
      return I18n.t(`serverErrorMessages.${["40002"]}`, exception(errorCode));
    case 40003:
      return I18n.t(`serverErrorMessages.${["40003"]}`, exception(errorCode));
    case 40010:
      return I18n.t(`serverErrorMessages.${["40010"]}`, exception(errorCode));
    case 40301:
      return I18n.t(`serverErrorMessages.${["40301"]}`, exception(errorCode));
    case 40302:
      return I18n.t(`serverErrorMessages.${["40302"]}`, exception(errorCode));
    case 40409:
      return I18n.t(`serverErrorMessages.${["40409"]}`, exception(errorCode));
    case 40602:
      return I18n.t(`serverErrorMessages.${["40602"]}`, exception(errorCode));
    case 40604:
      return I18n.t(`serverErrorMessages.${["40604"]}`, exception(errorCode));
    case 40605:
      return I18n.t(`serverErrorMessages.${["40605"]}`, exception(errorCode));
    case 40607:
      return I18n.t(`serverErrorMessages.${["40607"]}`, exception(errorCode));
    case 40610:
      return I18n.t(`serverErrorMessages.${["40610"]}`, exception(errorCode));
    case 40617:
      return I18n.t(`serverErrorMessages.${["40617"]}`, exception(errorCode));
    case 40702:
      return I18n.t(`serverErrorMessages.${["40702"]}`, exception(errorCode));
    case 41003:
      return I18n.t(`serverErrorMessages.${["41003"]}`, exception(errorCode));
    case 41004:
      return I18n.t(`serverErrorMessages.${["41004"]}`, exception(errorCode));
    case 50000:
      return I18n.t(`serverErrorMessages.${["50000"]}`, exception(errorCode));
    case 50001:
      return I18n.t(`serverErrorMessages.${["50001"]}`, exception(errorCode));
    case 703108:
      return I18n.t(`serverErrorMessages.${["703108"]}`, exception(errorCode));
    case 703111:
      return I18n.t(`serverErrorMessages.${["703111"]}`, exception(errorCode));
    case 703112:
      return I18n.t(`serverErrorMessages.${["703112"]}`, exception(errorCode));
    case 703117:
      return I18n.t(`serverErrorMessages.${["703117"]}`, exception(errorCode));
    case 703201:
      return I18n.t(`serverErrorMessages.${["703201"]}`, exception(errorCode));
    case 703202:
      return I18n.t(`serverErrorMessages.${["703202"]}`, exception(errorCode));
    case 703203:
      return I18n.t(`serverErrorMessages.${["703203"]}`, exception(errorCode));
    case 703104:
      return I18n.t(`serverErrorMessages.${["703104"]}`, exception(errorCode));
    case 703106:
      return I18n.t(`serverErrorMessages.${["703106"]}`, exception(errorCode));
    default:
      return I18n.t(
        `serverErrorMessages.${[`${errorCode}`]}`,
        exception(errorCode)
      );
  }
};
